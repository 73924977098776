<template>

  <b-row>
    <b-col
      cols="12"
      md="12"
    >
      <b-card title="Kategoriyalar">

        <b-form @submit.prevent>
          <b-row>
            <b-col md="4">

              <b-form-group>
                <label for="ageCategoryTypes">Kategoriya turi:</label>
                <v-select
                     v-model="category.type"
                     :options="ageCategoryTypes"
                     label="text"
                     :reduce="option => option.value"
                    placeholder="Kategoriya turi"

                />
              </b-form-group>

            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="startPoint">Bola yoshi / dan:</label>
                <b-form-input
                    ref="startPointInput"
                    type="number"
                    id="startPoint"
                    v-model="category.startPoint"
                    placeholder="Bola yoshi / dan "
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="endPoint">Bola yoshi / gacha:</label>
                <b-form-input
                    id="endPoint"
                    type="number"
                    v-model="category.endPoint"
                    placeholder="Bola yoshi / gacha"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="warning"
                  class="mr-1"
                  @click="CategoryFormClear()"
              >
                Tozlash
              </b-button>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  @click="submitCategoryForm"
              >
                Saqlash
              </b-button>

            </b-col>
          </b-row>
        </b-form>

        <hr>
        <b-table
            hover
            responsive="sm"
            :items="items"
            :fields="fields"

        >
          <template #cell(editButton)="data">
            <div class="edit-button-container cursor-pointer" @click="openEditCategory(data.item.id)">
              <feather-icon
                  icon="EditIcon"
                  class="mr-75"
                  size="18"
                  @mouseover="handleMouseOver"
                  @mouseout="handleMouseOut"
              />
              Tahrirlash
            </div>
          </template>
        </b-table>

        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
        />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import vSelect from 'vue-select'

import {
  BButton,
  BModal,
  BTable,
    BFormSelect,
  BPagination,
  VBModal,
  BFormCheckbox,
  BRow,
  BCol,
  BForm,
  BCard,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider } from 'vee-validate'
import { codeClearing } from '@/views/forms/form-element/form-file-input/code'

export default {
  components: {
    ValidationProvider,
    BRow,
    BCol,
    vSelect,
    BTable,
    BFormSelect,
    BPagination,
    BCard,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormCheckbox,

  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      editedUserId: null,
      ageCategoryTypes: [
        { value: 'MONTH', text: 'Oy' },
        { value: 'YEAR', text: 'Yil' },
      ],

      category: {
        id: null,
        type: '',
        startPoint: null,
        endPoint: null,
        isDeleted: null,
      },
      fields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'type', label: 'Kategoriya turi' },
        { key: 'startPoint', label: 'Bola yoshi / dan' },
        { key: 'endPoint', label: 'Bola yoshi / gacha' },
        { key: 'editButton', label: 'Tahrirlash' },
      ],
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      users: [],
      items: [],
      tableKey:0,
    }
  },
watch: {
  currentPage() {
    this.getcategory()
  },
},
  created() {
    this.getcategory()
  },
  methods: {
    getcategory() {
      const currentPage = this.currentPage -1;
      this.$http
        .post(`/age-category/find-all?page=${currentPage}&size=${this.perPage}`)
        .then(response => {
          this.totalRows = response.data.totalElements;
          this.items = response.data.content;
        })
        .catch(error => {
          console.error('Axios error:', error)
        })
    },

    openEditCategory(categoryId) {
      const categoryToEdit = this.items.find(category => category.id === categoryId)
      this.category = { ...categoryToEdit }
      this.$nextTick(() => {
        this.$refs.startPointInput.focus();
      });
    },
    CategoryFormClear() {
      this.category = {};
    },
    submitCategoryForm() {
      this.$http
        .post(`/age-category/save`, this.category)
        .then(() => {

          this.category={};
          this.getcategory();
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kategoriya',
              icon: 'AlertTriangleIcon',
              variant: 'success',
              text: "Ma'lumot muvaffaqiyatli saqlandi !",
            },
          })

          this.$bvModal.hide('modal-login')
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Xatolik yuz berdi',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: "Ma'lumotni saqlashda xatolik yuz berdi. Qaytadan urinib ko'ring",
            },
          })
        })
    },
    handleMouseOver(event) {
      event.target.classList.add('hovered-icon');
    },
    handleMouseOut(event) {
      event.target.classList.remove('hovered-icon');
    },
  },


}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
.hovered-icon{
  transition: transform 0.25s ease;
  cursor: pointer;
  font-size: 12px;
  color: #7367f0;

}
</style>
